import { useRef } from 'react';
import {
  Box,
  Container,
  Flex,
  Select,
  TextInput,
  ActionIcon,
  Stack,
  Divider,
  NumberInput,
} from '@mantine/core';
import { TimeInput, DatePickerInput } from '@mantine/dates';
import { BroadcastWindowConstraint } from 'utils/constraintConsts';
import {
  DAY_KEYS,
  DayType,
  HolidaySlot,
  HolidaySlotTranslations,
  NETWORK_KEYS_SIMPLE,
  NetworkTypeSimple,
  RecursivePartial,
  SLOT_TYPE_KEYS,
  SlotType,
} from 'utils/scheduleConsts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/sharp-regular-svg-icons';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const BroadcastWindowEntry = ({
  constraint,
  onChange,
}: {
  constraint: RecursivePartial<BroadcastWindowConstraint>;
  onChange: (value: RecursivePartial<BroadcastWindowConstraint>) => void;
  // eslint-disable-next-line arrow-body-style
}) => {
  const timePickerRef = useRef<HTMLInputElement>(null);

  const pickerControl = (
    <ActionIcon variant="subtle" color="gray" onClick={() => timePickerRef.current?.showPicker()}>
      <FontAwesomeIcon icon={faClock} height={16} width={16} />
    </ActionIcon>
  );
  return (
    <Stack>
      <Flex gap="xl">
        <TextInput
          disabled={constraint.locked}
          w="240"
          description="Name"
          value={constraint.name}
          onChange={(event) => onChange({ ...constraint, name: event.currentTarget.value })}
          inputWrapperOrder={['input', 'description']}
        />
        <Select
          disabled={constraint.locked}
          w="120"
          withCheckIcon={false}
          allowDeselect={false}
          value={constraint.network}
          onChange={(network) =>
            onChange({
              ...constraint,
              network: network as NetworkTypeSimple,
            })
          }
          data={NETWORK_KEYS_SIMPLE.slice().sort()}
          searchable
          description="Network"
          inputWrapperOrder={['input', 'description']}
        />
        <Select
          disabled={constraint.locked}
          w="120"
          withCheckIcon={false}
          allowDeselect={false}
          value={constraint.day}
          onChange={(day) =>
            onChange({
              ...constraint,
              day: day as DayType,
            })
          }
          data={DAY_KEYS.map((day) => ({
            label: day.charAt(0).toUpperCase() + day.slice(1),
            value: day,
          }))}
          searchable
          description="Day of week"
          inputWrapperOrder={['input', 'description']}
        />
        <TimeInput
          disabled={constraint.locked}
          w="130"
          description="Start time"
          ref={timePickerRef}
          value={constraint.start_time}
          onChange={(e) => {
            onChange({ ...constraint, start_time: e.currentTarget.value });
          }}
          inputWrapperOrder={['input', 'description']}
          rightSection={pickerControl}
        />
        <DatePickerInput
          w="200"
          type="range"
          valueFormat="ddd MMM D"
          description="Date range"
          allowSingleDateInRange
          value={[
            constraint.start_date ? dayjs(constraint.start_date).toDate() : null,
            constraint.end_date ? dayjs(constraint.end_date).toDate() : null,
          ]}
          onChange={(e) => {
            const [startDate, endDate] = e;
            onChange({
              ...constraint,
              start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
              end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
            });
          }}
          inputWrapperOrder={['input', 'description']}
        />
      </Flex>
      <Divider />
      <Flex gap="xl">
        <DatePickerInput
          w="140"
          type="multiple"
          description="Off dates"
          clearable
          valueFormat="ddd MMM D"
          value={constraint?.off_dates?.map((date) => dayjs(date).toDate())}
          onChange={(e) => {
            onChange({
              ...constraint,
              off_dates: e.map((date) => dayjs(date).format('YYYY-MM-DD')),
            });
          }}
          inputWrapperOrder={['input', 'description']}
        />
        <DatePickerInput
          w="140"
          type="multiple"
          description="Guarantee dates"
          clearable
          valueFormat="ddd MMM D"
          value={constraint?.guarantee_dates?.map((date) => dayjs(date).toDate())}
          onChange={(e) => {
            onChange({
              ...constraint,
              guarantee_dates: e.map((date) => dayjs(date).format('YYYY-MM-DD')),
            });
          }}
          inputWrapperOrder={['input', 'description']}
        />
        <NumberInput
          w="80"
          description="Min games"
          value={constraint.min_games}
          onChange={(e) => {
            const value = Number(e);
            if (value <= (constraint.max_games ?? 0) && value >= 0) {
              onChange({ ...constraint, min_games: value });
            }
          }}
          inputWrapperOrder={['input', 'description']}
        />
        <NumberInput
          w="80"
          description="Max games"
          value={constraint.max_games}
          onChange={(e) => {
            const value = Number(e);
            if (value >= (constraint.min_games ?? 0) && value >= 0) {
              onChange({ ...constraint, max_games: value });
            }
          }}
          inputWrapperOrder={['input', 'description']}
        />
        <Select
          w="140"
          withCheckIcon={false}
          description="Slot type"
          value={constraint.slot_type}
          onChange={(slotType) => onChange({ ...constraint, slot_type: slotType as SlotType })}
          data={SLOT_TYPE_KEYS}
          inputWrapperOrder={['input', 'description']}
        />
        <Select
          w="120"
          withCheckIcon={false}
          description="International"
          inputWrapperOrder={['input', 'description']}
          allowDeselect={false}
          data={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          value={constraint.international ? 'true' : 'false'}
          onChange={(value) => onChange({ ...constraint, international: value === 'true' })}
        />
        <Select
          w="140"
          clearable
          description="Holiday"
          inputWrapperOrder={['input', 'description']}
          data={Object.entries(HolidaySlotTranslations).map(([key, value]) => ({
            label: value,
            value: key,
          }))}
          value={constraint.holiday}
          onChange={(value) =>
            onChange({ ...constraint, holiday: (value as HolidaySlot) || undefined })
          }
        />
      </Flex>
    </Stack>
  );
};

export function BroadcastWindowConstraintEditor({
  value,
  onChange,
}: {
  value: RecursivePartial<BroadcastWindowConstraint>;
  onChange: (value: RecursivePartial<BroadcastWindowConstraint>) => void;
}) {
  return (
    <Box>
      <Container size="xl">
        <BroadcastWindowEntry constraint={value} onChange={onChange} />
      </Container>
    </Box>
  );
}
