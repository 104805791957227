import { createFileRoute } from '@tanstack/react-router';

import {
  Container,
  Stack,
  Tabs,
  Title,
  Button,
  Divider,
  Group,
  TextInput,
  Modal,
  Select,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAtom,
  faBolt,
  faCastle,
  faCircleInfo,
  faPeopleArrows,
  faPeopleGroup,
  faPodcast,
  faTvRetro,
} from '@fortawesome/sharp-regular-svg-icons';
import StadiumBlockChart from 'components/StadiumBlockChart';
import { useDisclosure } from '@mantine/hooks';
import { useAppStore } from 'stores/appStore';
import { useState } from 'react';
import { getBuildContext } from 'api/api';
import { removeDuplicateConstraints } from 'utils/scheduleUtils';
import NetworkDials from 'components/NetworkDials';
import DynamicConstraintsTable from 'components/DynamicConstraintsTable';
import BroadcastWindowConstraintsTable from 'components/BroadcastWindowConstraintsTable';
import CoreConstraintsTable from 'components/CoreConstraintsTable';
import MatchupConstraintsTable from 'components/MatchupConstraintsTable';
import TeamConstraintsTable from 'components/TeamConstraintsTable';
import NetworkConstraintsTable from 'components/NetworkConstraintsTable';

function ImportModal({ opened, onClose }: { opened: boolean; onClose: () => void }) {
  const {
    buildsList,
    setIsAppLoading,
    draftConstraints,
    upgradeAndSaveConstraints,
    draftBaseBuildIds,
    setDraftBaseBuildIds,
  } = useAppStore((state) => ({
    buildsList: state.buildsList,
    setIsAppLoading: state.setIsAppLoading,
    draftConstraints: state.draftConstraints,
    upgradeAndSaveConstraints: state.upgradeAndSaveConstraints,
    draftBaseBuildIds: state.draftBaseBuildIds,
    setDraftBaseBuildIds: state.setDraftBaseBuildIds,
  }));
  const [selectedBuildId, setSelectedBuildId] = useState('');

  const handleImportButtonClick = async () => {
    setIsAppLoading(true);
    const newContext = await getBuildContext(selectedBuildId);
    const newConstraints = removeDuplicateConstraints([
      ...draftConstraints,
      ...newContext.constraints,
    ]);
    upgradeAndSaveConstraints(newConstraints);

    if (!draftBaseBuildIds.includes(selectedBuildId)) {
      setDraftBaseBuildIds([...draftBaseBuildIds, selectedBuildId]);
    }
    setIsAppLoading(false);
    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose}>
      <Group align="end">
        <Select
          w={400}
          label="Import from previous build"
          searchable
          placeholder="Select build"
          data={buildsList.map((build) => ({
            label: build.name,
            value: build.id,
          }))}
          allowDeselect={false}
          value={selectedBuildId}
          onChange={(_value, option) => setSelectedBuildId(option.value)}
        />
        <Button
          ml="auto"
          variant="filled"
          onClick={handleImportButtonClick}
          disabled={selectedBuildId === ''}
        >
          Import
        </Button>
      </Group>
    </Modal>
  );
}

function ScheduleBuilder() {
  const {
    buildsList,
    draftBaseBuildIds,
    draftWarmStartId,
    setDraftWarmStartId,
    getOptimalContextPayload,
    draftBuildName,
    setDraftBuildName,
  } = useAppStore((state) => ({
    buildsList: state.buildsList,
    draftBaseBuildIds: state.draftBaseBuildIds,
    draftWarmStartId: state.draftWarmStartId,
    setDraftWarmStartId: state.setDraftWarmStartId,
    getOptimalContextPayload: state.getOptimalContextPayload,
    draftBuildName: state.draftBuildName,
    setDraftBuildName: state.setDraftBuildName,
  }));

  const [importModalOpened, importModalHandlers] = useDisclosure(false);
  return (
    <Container fluid mt="md" mb="xl" miw={1100}>
      <ImportModal opened={importModalOpened} onClose={importModalHandlers.close} />
      <Stack>
        <Stack gap="2px">
          <Title order={2}>Schedule Builder</Title>
          <Divider />
          <Group align="end">
            <TextInput
              label="Build name"
              placeholder="Enter build name..."
              w="400px"
              value={draftBuildName}
              onChange={(event) => setDraftBuildName(event.currentTarget.value)}
            />
            <Button variant="filled" color="green" size="sm">
              Submit
            </Button>
            <Button variant="outline" size="sm" ml="200px" onClick={importModalHandlers.open}>
              Import
            </Button>
            <Divider orientation="vertical" mt="24px" />

            <Button
              onClick={() => {
                useAppStore.persist.clearStorage();
                window.location.reload();
              }}
              variant="outline"
              color="red"
              size="sm"
            >
              Reset
            </Button>
            <Button
              variant="filled"
              color="orange"
              size="sm"
              onClick={() => {
                const context = getOptimalContextPayload();
                const blob = new Blob([JSON.stringify(context, null, 2)], {
                  type: 'application/json',
                });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'optimal_context.json';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
              }}
            >
              Optimal Context
            </Button>
          </Group>
        </Stack>
        <Tabs radius="xs" defaultValue="settings" keepMounted={false}>
          <Tabs.List>
            <Tabs.Tab value="settings" leftSection={<FontAwesomeIcon icon={faCircleInfo} />}>
              Settings
            </Tabs.Tab>
            <Tabs.Tab value="broadcastWindows" leftSection={<FontAwesomeIcon icon={faPodcast} />}>
              Broadcast Windows
            </Tabs.Tab>
            <Tabs.Tab value="stadiumBlocks" leftSection={<FontAwesomeIcon icon={faCastle} />}>
              Stadium Blocks
            </Tabs.Tab>
            <Tabs.Tab value="coreConstraints" leftSection={<FontAwesomeIcon icon={faAtom} />}>
              Core Constraints
            </Tabs.Tab>
            <Tabs.Tab
              value="matchupConstraints"
              leftSection={<FontAwesomeIcon icon={faPeopleArrows} />}
            >
              Matchups
            </Tabs.Tab>
            <Tabs.Tab
              value="teamConstraints"
              leftSection={<FontAwesomeIcon icon={faPeopleGroup} />}
            >
              Teams
            </Tabs.Tab>
            <Tabs.Tab value="networkConstraints" leftSection={<FontAwesomeIcon icon={faTvRetro} />}>
              Networks
            </Tabs.Tab>
            <Tabs.Tab value="dynamicConstraints" leftSection={<FontAwesomeIcon icon={faBolt} />}>
              Dynamic Constraints (Deprecated)
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="settings" mt="sm">
            <Stack gap="lg">
              <Select
                w={400}
                label="Warm start schedule (optional)"
                placeholder="Import builds to see options"
                description="Select a warm start schedule to speed up your build"
                data={buildsList
                  .filter((build) => draftBaseBuildIds.includes(build.id))
                  .flatMap((build) => build.results)
                  .filter((result) => result.is_valid)
                  .map((result) => ({
                    label: result.name,
                    value: result.id,
                  }))}
                value={draftWarmStartId}
                onChange={(_value, option) => setDraftWarmStartId(option?.value || '')}
              />
              <NetworkDials />
            </Stack>
          </Tabs.Panel>
          <Tabs.Panel value="broadcastWindows" mt="sm">
            <BroadcastWindowConstraintsTable />
          </Tabs.Panel>
          <Tabs.Panel value="stadiumBlocks" mt="sm">
            <StadiumBlockChart isEditable />
          </Tabs.Panel>
          <Tabs.Panel value="coreConstraints" mt="sm">
            <CoreConstraintsTable />
          </Tabs.Panel>
          <Tabs.Panel value="matchupConstraints" mt="sm">
            <MatchupConstraintsTable />
          </Tabs.Panel>
          <Tabs.Panel value="teamConstraints" mt="sm">
            <TeamConstraintsTable />
          </Tabs.Panel>
          <Tabs.Panel value="networkConstraints" mt="sm">
            <NetworkConstraintsTable />
          </Tabs.Panel>
          <Tabs.Panel value="dynamicConstraints" mt="sm">
            <DynamicConstraintsTable />
          </Tabs.Panel>
        </Tabs>
      </Stack>
    </Container>
  );
}

export const Route = createFileRoute('/_authenticated/schedulebuilder')({
  component: ScheduleBuilder,
});
