/* eslint-disable no-restricted-syntax */
import { getBuildsList, getUploadsList, isUsingLocalFolders } from 'api/api';
import { getAllScheduleDataRemote, getBuildContextRemote } from 'api/remoteApi';
import {
  BroadcastWindowConstraint,
  CoreConstraintEntry,
  MatchupConstraint,
  NetworkConstraint,
  OptimalContextPayload,
  StadiumBlockConstraint,
  TeamConstraint,
} from 'utils/constraintConsts';
import {
  DEFAULT_BROADCAST_WINDOW_CONSTRAINTS,
  DEFAULT_CORE_CONSTRAINTS,
} from 'utils/constraintDefaults';
import { upgradeDynamicConstraints } from 'utils/constraintUtils';
import {
  BuildListEntry,
  ByeThursIndex,
  ConstraintEntry,
  JobManagerEntry,
  MarketsSummaryIndex,
  MatchupIndex,
  NetworkSummaryIndexSimple,
  PercentageThresholdsIndex,
  RawSchedule,
  RestSummaryIndex,
  ScheduleListEntry,
  SummaryHighlightsInfo,
  TeamSummaryIndex,
  UpgradedScheduleEntry,
  UploadsListEntry,
  ViewershipPredictionsIndex,
  WeekSummaryIndex,
} from 'utils/scheduleConsts';
import {
  getByeThursSummary,
  getConstraints,
  getEmptyByeThursSummary,
  getEmptyNetworkSummary,
  getEmptyRestSummary,
  getEmptySchedule,
  getNetworkSummmary,
  getRestSummary,
  getSchedule,
  getSummaryHighlights,
  getTeamSummary,
  getViewershipPredictions,
  getWeekSummary,
  processRawByeThursSummary,
  processRawNetworkSummarySimple,
  processRawRestSummary,
  processViewershipPredictionsDataRaw,
  upgradeRawSchedule,
} from 'utils/scheduleUtils';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface AppState {
  userEmail: string;
  setUserEmail: (email: string) => void;
  isAppLoading: boolean;
  setIsAppLoading: (isAppLoading: boolean) => void;
  isScheduleLoading: boolean;
  setIsScheduleLoading: (isScheduleLoading: boolean) => void;
  currentScheduleId: string;
  setCurrentScheduleId: (id: string) => void;
  currentScheduleName: string;
  setCurrentScheduleName: (id: string) => void;
  schedulesList: ScheduleListEntry[];
  unfilteredBuildsList: BuildListEntry[];
  buildsList: BuildListEntry[];
  uploadsList: UploadsListEntry[];
  currentSchedule: MatchupIndex[];
  currentUpgradedSchedule: UpgradedScheduleEntry[];
  currentConstraints: ConstraintEntry[];
  currentStadiumBlockConstraints: StadiumBlockConstraint[];
  currentPercentageThresholds: PercentageThresholdsIndex | null;
  currentSummaryHighlights: SummaryHighlightsInfo | null;
  currentNetworkSummary: NetworkSummaryIndexSimple[];
  currentTeamSummary: TeamSummaryIndex | null;
  currentWeekSummary: WeekSummaryIndex | null;
  currentByeThursSummary: ByeThursIndex | null;
  currentRestSummary: RestSummaryIndex | null;
  currentMarketsSummary: MarketsSummaryIndex | null;
  currentViewershipPredictions: ViewershipPredictionsIndex | null;
  currentScheduleXlsxUrl: string;
  jobManagerEntries: JobManagerEntry[];
  setJobManagerEntries: (entries: JobManagerEntry[]) => void;
  fetchBuildsList: (targetScheduleId?: string) => void;
  fetchScheduleData: (id: string) => void;
  getNextBuildName: () => string;
  draftConstraints: ConstraintEntry[];
  setDraftConstraints: (constraints: ConstraintEntry[]) => void;
  upgradeAndSaveConstraints: (constraints: ConstraintEntry[]) => void;
  draftBaseBuildIds: string[];
  draftWarmStartId: string;
  setDraftBaseBuildIds: (buildIds: string[]) => void;
  setDraftWarmStartId: (id: string) => void;
  draftPercentageThresholds: PercentageThresholdsIndex;
  setDraftPercentageThresholds: (percentageThresholds: PercentageThresholdsIndex) => void;
  updateDraftPercentageThresholds: (key: keyof PercentageThresholdsIndex, value: number) => void;
  draftBroadcastWindowConstraints: BroadcastWindowConstraint[];
  setDraftBroadcastWindowConstraints: (constraints: BroadcastWindowConstraint[]) => void;
  draftCoreConstraints: CoreConstraintEntry[];
  setDraftCoreConstraints: (constraints: CoreConstraintEntry[]) => void;
  draftStadiumBlockConstraints: StadiumBlockConstraint[];
  setDraftStadiumBlockConstraints: (constraints: StadiumBlockConstraint[]) => void;
  draftMatchupConstraints: MatchupConstraint[];
  setDraftMatchupConstraints: (constraints: MatchupConstraint[]) => void;
  draftTeamConstraints: TeamConstraint[];
  setDraftTeamConstraints: (constraints: TeamConstraint[]) => void;
  draftNetworkConstraints: NetworkConstraint[];
  setDraftNetworkConstraints: (constraints: NetworkConstraint[]) => void;
  draftBuildName: string;
  setDraftBuildName: (name: string) => void;
  getOptimalContextPayload: () => OptimalContextPayload;
}

export const useAppStore = create(
  persist<AppState>(
    (set, get) => ({
      userEmail: '',
      setUserEmail: (email: string) => set(() => ({ userEmail: email })),
      isAppLoading: true,
      setIsAppLoading: (isAppLoading: boolean) => set(() => ({ isAppLoading })),
      isScheduleLoading: false,
      setIsScheduleLoading: (isScheduleLoading: boolean) => set(() => ({ isScheduleLoading })),
      currentScheduleId: '',
      setCurrentScheduleId: (id: string) => set(() => ({ currentScheduleId: id })),
      currentScheduleName: '',
      setCurrentScheduleName: (name: string) => set(() => ({ currentScheduleName: name })),
      schedulesList: [],
      unfilteredBuildsList: [],
      buildsList: [],
      uploadsList: [],
      setSchedulesList: (schedules: ScheduleListEntry[]) =>
        set(() => ({ schedulesList: schedules })),
      currentSchedule: getEmptySchedule(),
      currentUpgradedSchedule: [],
      currentConstraints: [],
      currentStadiumBlockConstraints: [],
      currentPercentageThresholds: null,
      currentSummaryHighlights: null,
      currentNetworkSummary: getEmptyNetworkSummary(),
      currentTeamSummary: null,
      currentWeekSummary: null,
      currentByeThursSummary: getEmptyByeThursSummary(),
      currentRestSummary: null,
      currentMarketsSummary: null,
      currentViewershipPredictions: null,
      currentScheduleXlsxUrl: '',
      jobManagerEntries: [],
      setJobManagerEntries: (entries: JobManagerEntry[]) =>
        set(() => ({ jobManagerEntries: entries })),
      // TODO(maciek): Rename this - it fetches all build list data, populates the schedules list,
      // fetches data for the current schedule, and gets all user upload bundle and associated
      // schedule file names.
      fetchBuildsList: async (targetScheduleId?: string) => {
        set(() => ({ isAppLoading: true }));
        try {
          const unfilteredBuildsList = await getBuildsList();
          set(() => ({ unfilteredBuildsList }));

          // Remove builds with no jobs attached to them, as they are either premature or
          // invalid.
          const buildsList = unfilteredBuildsList.filter((build) => build.jobs.length > 0);

          // Sort builds by date descending (newest first).
          buildsList.sort(
            (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );

          // Sort schedules by date ascending (newest last).
          buildsList.forEach((buildEntry) => {
            buildEntry.results.sort(
              (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            );
          });

          // Get all schedules from the builds list.
          const schedulesList = buildsList.flatMap((build) => build.results);
          set(() => ({ schedulesList }));
          set(() => ({ buildsList }));
          if (
            targetScheduleId &&
            schedulesList.find((schedule) => schedule.id === targetScheduleId)
          ) {
            set(() => ({ currentScheduleId: targetScheduleId }));
          } else if (get().currentScheduleId === '' && schedulesList.length) {
            set(() => ({ currentScheduleId: schedulesList[0].id }));
          } else if (get().currentScheduleId !== '') {
            set(() => ({
              currentScheduleName:
                schedulesList.find((schedule) => schedule.id === get().currentScheduleId)?.name ||
                '',
            }));
          }

          // Set the uploads list.
          const uploadsList = await getUploadsList();
          set(() => ({ uploadsList }));

          set(() => ({ isAppLoading: false }));
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error in data fetch:', err);
          set(() => ({ isAppLoading: false }));

          // set({ ...initialState, error: true, errorData: err.message });
        }
      },
      fetchScheduleData: async (id: string) => {
        set(() => ({ isScheduleLoading: true }));
        try {
          if (isUsingLocalFolders()) {
            await new Promise((f) => {
              setTimeout(f, 1000);
            });

            set(() => ({
              currentScheduleName:
                get().schedulesList.find((schedule) => schedule.id === id)?.name || '',
            }));

            const scheduleData = await getSchedule(id);
            set(() => ({ currentSchedule: scheduleData }));

            const constraints = await getConstraints(id);
            set(() => ({ currentConstraints: constraints }));

            // Upgrade the stadium block constraints.
            const upgradedConstraints = upgradeDynamicConstraints(constraints);
            set(() => ({
              currentStadiumBlockConstraints: upgradedConstraints.stadiumBlockConstraints,
            }));

            const summaryHightlights = await getSummaryHighlights(id);
            set(() => ({ currentSummaryHighlights: summaryHightlights }));

            const networkSummary = await getNetworkSummmary(id);
            set(() => ({ currentNetworkSummary: networkSummary }));

            const teamSummary = await getTeamSummary(id);
            set(() => ({ currentTeamSummary: teamSummary }));

            const weekSummary = await getWeekSummary(id);
            set(() => ({ currentWeekSummary: weekSummary }));

            const viewershipPredictions = await getViewershipPredictions(id);
            set(() => ({ currentViewershipPredictions: viewershipPredictions }));

            const byeThursSummary = await getByeThursSummary(id);
            set(() => ({ currentByeThursSummary: byeThursSummary }));

            const restSummary = await getRestSummary(id);
            set(() => ({ currentRestSummary: restSummary }));
          } else {
            const fullScheduleData = await getAllScheduleDataRemote(id);
            const context = await getBuildContextRemote(fullScheduleData.build_id);
            set(() => ({ currentConstraints: context.constraints }));

            // Upgrade the stadium block constraints.
            const upgradedConstraints = upgradeDynamicConstraints(context.constraints);
            set(() => ({
              currentStadiumBlockConstraints: upgradedConstraints.stadiumBlockConstraints,
            }));

            set(() => ({ currentPercentageThresholds: context.percentageThresholds }));

            set(() => ({ currentScheduleName: fullScheduleData.name }));
            // set(() => ({ currentSchedule: processRawSchedule(fullScheduleData.results.schedule) }));
            // Check to see if the schedule type has been upgraded. If not, upgrade it.
            const isUpgradedSchedule = Array.isArray(fullScheduleData.results.schedule);
            set(() => ({
              currentUpgradedSchedule: isUpgradedSchedule
                ? (fullScheduleData.results.schedule as UpgradedScheduleEntry[])
                : upgradeRawSchedule(fullScheduleData.results.schedule as RawSchedule),
            }));
            set(() => ({
              currentSummaryHighlights: fullScheduleData.results.summary_highlights,
            }));
            set(() => ({
              currentNetworkSummary: processRawNetworkSummarySimple(
                fullScheduleData.results.summary_network_teams
              ),
            }));
            set(() => ({
              // Adding an undefined check until this is stabilized.
              currentByeThursSummary: fullScheduleData.results.summary_bye_thurs
                ? processRawByeThursSummary(fullScheduleData.results.summary_bye_thurs)
                : getEmptyByeThursSummary(),
            }));
            set(() => ({
              // Adding an undefined check until this is stabilized.
              currentRestSummary: fullScheduleData.results.summary_rest
                ? processRawRestSummary(fullScheduleData.results.summary_rest)
                : getEmptyRestSummary(),
            }));
            set(() => ({ currentTeamSummary: fullScheduleData.results.summary_teams }));
            set(() => ({ currentMarketsSummary: fullScheduleData.results.summary_markets }));
            set(() => ({ currentWeekSummary: fullScheduleData.results.summary_weeks }));
            set(() => ({
              currentViewershipPredictions: processViewershipPredictionsDataRaw(
                fullScheduleData.results.summary_network_games
              ),
            }));
            set(() => ({ currentScheduleXlsxUrl: fullScheduleData.excel_schedule }));
          }

          set(() => ({ isScheduleLoading: false }));
          // const res = await axios.get("https://jsonplaceholder.typicode.com/users");
          // set({ ...initialState, success: true, data: res.data });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error in data fetch:', err);
          set(() => ({ isScheduleLoading: false }));
          // set({ ...initialState, error: true, errorData: err.message });
        }
      },
      getNextBuildName: () => {
        const allBuildNames = get().unfilteredBuildsList.map((build) => build.name);
        const allUploadNames = get().uploadsList.map((upload) => upload.name);
        const allNames = [...allBuildNames, ...allUploadNames];

        const baseBuildName = `${new Date().toDateString()}`;
        let suggestedBuildName = baseBuildName;
        let i = 2;
        while (allNames.includes(suggestedBuildName)) {
          suggestedBuildName = `${baseBuildName} (${i})`;
          i += 1;
        }
        return suggestedBuildName;
      },
      draftConstraints: [],
      setDraftConstraints: (constraints: ConstraintEntry[]) =>
        set(() => ({ draftConstraints: constraints })),
      upgradeAndSaveConstraints: (constraints: ConstraintEntry[]) => {
        set(() => ({ draftConstraints: constraints }));

        // Upgrade old constraints.
        const upgradedConstraints = upgradeDynamicConstraints(constraints);
        set(() => ({ draftStadiumBlockConstraints: upgradedConstraints.stadiumBlockConstraints }));
        set(() => ({
          draftMatchupConstraints: upgradedConstraints.matchupConstraints,
        }));
        set(() => ({
          draftTeamConstraints: upgradedConstraints.teamConstraints,
        }));
        set(() => ({
          draftNetworkConstraints: upgradedConstraints.networkConstraints,
        }));
      },
      draftBaseBuildIds: [],
      setDraftBaseBuildIds: (buildIds: string[]) => set(() => ({ draftBaseBuildIds: buildIds })),
      draftPercentageThresholds: {},
      setDraftPercentageThresholds: (percentageThresholds: PercentageThresholdsIndex) =>
        set(() => ({ draftPercentageThresholds: percentageThresholds })),
      draftWarmStartId: '',
      setDraftWarmStartId: (id: string) => {
        set(() => ({ draftWarmStartId: id }));
      },
      updateDraftPercentageThresholds: (key: keyof PercentageThresholdsIndex, value: number) =>
        set((state) => ({
          draftPercentageThresholds: { ...state.draftPercentageThresholds, [key]: value },
        })),
      draftBroadcastWindowConstraints: DEFAULT_BROADCAST_WINDOW_CONSTRAINTS,
      setDraftBroadcastWindowConstraints: (constraints: BroadcastWindowConstraint[]) =>
        set(() => ({ draftBroadcastWindowConstraints: constraints })),
      draftCoreConstraints: DEFAULT_CORE_CONSTRAINTS,
      setDraftCoreConstraints: (constraints: CoreConstraintEntry[]) =>
        set(() => ({ draftCoreConstraints: constraints })),
      draftStadiumBlockConstraints: [],
      setDraftStadiumBlockConstraints: (constraints: StadiumBlockConstraint[]) =>
        set(() => ({ draftStadiumBlockConstraints: constraints })),
      draftMatchupConstraints: [],
      setDraftMatchupConstraints: (constraints: MatchupConstraint[]) =>
        set(() => ({ draftMatchupConstraints: constraints })),
      draftTeamConstraints: [],
      setDraftTeamConstraints: (constraints: TeamConstraint[]) =>
        set(() => ({ draftTeamConstraints: constraints })),
      draftNetworkConstraints: [],
      setDraftNetworkConstraints: (constraints: NetworkConstraint[]) =>
        set(() => ({ draftNetworkConstraints: constraints })),
      draftBuildName: '',
      setDraftBuildName: (name: string) => set(() => ({ draftBuildName: name })),
      getOptimalContextPayload: () => ({
        user: 'admin',
        name: get().draftBuildName,
        context: {
          core_constraints: get().draftCoreConstraints,
          broadcast_windows: get().draftBroadcastWindowConstraints,
          percentageThresholds: get().draftPercentageThresholds,
          stadium_block_constraints: get().draftStadiumBlockConstraints,
          matchup_constraints: get().draftMatchupConstraints,
          team_constraints: get().draftTeamConstraints,
          network_constraints: get().draftNetworkConstraints,
          baseBuildIds: get().draftBaseBuildIds,
          warm_start_id: get().draftWarmStartId,
        },
      }),
    }),
    {
      name: 'ra-nfl-app-store',
    }
  )
);
